import { Card, CardContent, CardMedia, Grid, makeStyles, Typography } from "@material-ui/core";
import { Fragment, React } from "react";
import { FadeInSection } from "./Helpers/FadeinSection";
import about2 from "../Img/about2.jpg";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        color: '#838C95',
        padding: 10,
        backgroundColor: 'transparent',
        boxShadow: 'none'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    pos: {
        marginBottom: 12,
        fontSize: 15
    },
    coveragesBackground: {
        // backgroundImage: `url(${background}) !important`,
        // backgroundSize: '50% 100%',
        backgroundColor: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
    },
    coveragesBackgroundMobil: {
        // backgroundImage: `url(${background}) !important`,
        // backgroundSize: '100% 100%',
        backgroundColor: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
});

const About = () => {
    const classes = useStyles();

    return (
        <Fragment>
            <section id="about">
                <h2>About</h2>

                <Grid container direction="row-reverse">
                    <Grid item xs={12} lg={6} style={{ padding: 20 }}>
                        <FadeInSection>
                            <Card className={classes.root}>
                                <CardContent>
                                    <Typography component="p">
                                        <b>Who we are</b>
                                    </Typography>
                                    <Typography className={classes.pos} color="textSecondary">
                                        <p>
                                            In 2021 The Trans Pro Group , Inc (TPG) was formed by a group of insurance industry executives each with over 30 years’ experience and various disciplines.
                                        </p>
                                        <p>
                                            TPG executives along with their group of experienced staff and associates are committed to help provide the markets and insurance coverages you need and are looking for.
                                        </p>
                                        <p>
                                            At TPG we offer Products and Programs to help you write your business and are committed to you on building a long-term business relationship.
                                        </p>
                                        <p>
                                            The TPG team is driven to help all our clients succeed and our team will always be available to help you by providing our cutting edge systems technology as we are extremely proficient at providing a very quick turnaround time once we receive a fully completed submission and all the required information.
</p>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </FadeInSection>
                    </Grid>
                    <Grid item xs={12} lg={6} style={{ padding: 20 }}>
                        <Card style={{ width: '100%' }}>
                            <CardMedia className={classes.media} image={about2} />
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={6} style={{ padding: 20 }}>

                    </Grid>
                    {/* <Grid item xs={12} lg={6} style={{ padding: 20 }}>
                        <FadeInSection>
                            <Card className={classes.root}>
                                <CardContent>
                                    <Typography component="p">
                                        <b>Locations</b>
                                    </Typography>
                                    <Typography className={classes.pos} color="textSecondary">
                                        <div>
                                            Insert Text Here. Insert Text Here. Insert Text Here. Insert Text Here. Insert Text Here. Insert Text Here. Insert Text Here. Insert Text Here. Insert Text Here.
                                    </div>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </FadeInSection>
                    </Grid> */}


                </Grid>


            </section>
        </Fragment>
    );
}

export default About;