import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Switch, Route, useHistory } from 'react-router-dom';
import Ontrak from './Components/Ontrak';
import Main from './Components/Index/Main';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/ontrak" component={Ontrak} />
          <Route exact path="/v1/ontrak" component={Ontrak} />
          <Route path="/" component={Main} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
