import { Card, CardContent, CardMedia, Grid, makeStyles, Typography } from "@material-ui/core";
import { Fragment, React } from "react";
import { FadeInSection } from "./Helpers/FadeinSection";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        color: '#838C95',
        padding: 10,
        backgroundColor: 'transparent',
        boxShadow: 'none'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    pos: {
        marginBottom: 12,
        fontSize: 15
    },
    coveragesBackground: {
        // backgroundImage: `url(${background}) !important`,
        // backgroundSize: '50% 100%',
        backgroundColor: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
    },
    coveragesBackgroundMobil: {
        // backgroundImage: `url(${background}) !important`,
        // backgroundSize: '100% 100%',
        backgroundColor: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
});

const GetQuote = () => {
    const classes = useStyles();
    return (
        <Fragment>
            <section id="getquote">
                <h2>Get a quote</h2>
                <Grid container>
                    {/* <Grid item xs={12} lg="6" style={{ padding: 20 }}>
                        <Card>
                            <CardMedia className={classes.media} />
                        </Card>
                    </Grid> */}
                    <Grid item xs={12} lg={12} style={{ padding: 20 }}>
                        <Card className={classes.root}>
                            <CardContent>
                                <Typography className={classes.pos} color="textSecondary">
                                    <FadeInSection>
                                        <p>
                                            An easy to fill out quote request is available for you to download it here. Once you filled it in, scan it or take a picture and then email it to us at quotes@thetranspro.com
                                    </p>
                                        <p>
                                            <a>Form (Coming soon)</a>
                                        </p>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                    </FadeInSection>

                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

            </section>
        </Fragment>
    );
}

export default GetQuote;