import { Fragment, React } from "react";
import { Link } from "react-router-dom";

const Resources = () => {
    return (
        <Fragment>
            <section id="resources">
                <div className="row">
                    <div className="twelve columns main-col">
                        <h2>Resources</h2>
                        <br />
                        <ul style={{ textAlign: 'left' }}>
                            {/* <li>
                                <h3>Under Construction</h3>
                            </li> */}
                            <li>
                                <Link to="/files/OnTrak Application.pdf" target="_blank" download>•	OnTrak Application</Link>
                            </li>
                            <li>
                                <Link to="/files/Trailblazers Submission & Eligibility Requirements).pdf" target="_blank" download>•	Trailblazers Submission & Eligibility Requirements</Link>
                            </li>
                            <li>
                                <Link to="/files/TB246.pdf" target="_blank" download>•	Commercial auto trucking application</Link>
                            </li>
                            <li>
                                <Link to="/files/TB247.pdf" target="_blank" download>•	Quote request</Link>
                            </li>
                            <li>
                                <Link to="/files/TB248.pdf" target="_blank" download>•	New venture supplement</Link>
                            </li>
                        </ul>
                        <br />
                    </div>
                </div>

            </section>
        </Fragment>
    );
}

export default Resources;