import { Button, Card, CardContent, CardMedia, Grid, makeStyles, TextField, Typography, useMediaQuery } from "@material-ui/core";
import { Fragment, React } from "react";
import { FadeInSection } from "./Helpers/FadeinSection";
import contact from "../Img/contact.jpg";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        color: '#838C95',
        padding: 10,
        backgroundColor: 'transparent',
        boxShadow: 'none'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    pos: {
        marginBottom: 12,
        fontSize: 15
    },
    // contactBackground: {
    //     backgroundImage: `url(${background}) !important`,
    //     backgroundSize: '50% 100%',
    //     backgroundColor: 'white',
    //     backgroundRepeat: 'no-repeat',
    //     backgroundPosition: 'right',
    // },
    // contactBackgroundMobil: {
    //     backgroundImage: `url(${background}) !important`,
    //     backgroundSize: '100% 100%',
    //     backgroundColor: 'white',
    //     backgroundRepeat: 'no-repeat',
    //     backgroundPosition: 'right'
    // }
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
});

const Contact = () => {
    const classes = useStyles();
    const width = useMediaQuery('(min-width:645px)');
    return (
        <Fragment>
            <section id="contact">
                <h2>Contact</h2>
                <Grid container direction="row-reverse" >

                    <Grid item xs={12} lg={6} style={{ padding: 60 }}>
                        <Grid container justify="center" style={{ backgroundColor: 'white', paddingTop: '30px' }}>
                            <Grid item xs={2} style={{ marginBottom: '10px' }}>
                            </Grid>
                            <Grid item xs={3}>
                                Your Name
                            </Grid>
                            <Grid item xs={5} >
                                <TextField id="outlined-basic" size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }} />
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={3}>
                                Telephone
                            </Grid>
                            <Grid item xs={5}>
                                <TextField id="outlined-basic" size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', backgroundColor: 'white' }} />
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={3}>
                                E-mail
                            </Grid>
                            <Grid item xs={5}>
                                <TextField id="outlined-basic" size="small" fullWidth variant="outlined" style={{ marginBottom: '10px', backgroundColor: 'white' }} />
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={3}>
                                Message
                            </Grid>
                            <Grid item xs={5}>
                                <TextField multiline rows={4} fullWidth id="outlined-basic" variant="outlined" style={{ backgroundColor: 'white' }} />
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={12} style={{ padding: '25px', textAlign: 'end' }}>
                                <Button variant="contained" color="primary">
                                    Reset
                                </Button>
                                <Button variant="contained" color="primary" style={{ marginLeft: '15px' }}>
                                    Send
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={6} style={{ padding: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Card style={{ width: '100%' }}>
                            <CardMedia className={classes.media} image={contact} />
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <p>Phone: (239) 791-1700</p>
                    </Grid>
                </Grid>
            </section>
        </Fragment>
    );
}

export default Contact;