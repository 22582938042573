import { Button, Card, CardContent, CardMedia, Grid, makeStyles, TextField, Typography, useMediaQuery, CardActionArea, CardActions } from "@material-ui/core";
import { Fragment, React } from "react";
import { FadeInSection } from "./Helpers/FadeinSection";
import trailblazersLogo from "../Img/trailblazersLogo.png";

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
        margin: 20
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    pos: {
        marginBottom: 12,
        fontSize: 15
    },
    // contactBackground: {
    //     backgroundImage: `url(${background}) !important`,
    //     backgroundSize: '50% 100%',
    //     backgroundColor: 'white',
    //     backgroundRepeat: 'no-repeat',
    //     backgroundPosition: 'right',
    // },
    // contactBackgroundMobil: {
    //     backgroundImage: `url(${background}) !important`,
    //     backgroundSize: '100% 100%',
    //     backgroundColor: 'white',
    //     backgroundRepeat: 'no-repeat',
    //     backgroundPosition: 'right'
    // }
    media: {
        height: 0,
        paddingTop: '80%', // 16:9
    },
});

const Markets = () => {
    const classes = useStyles();
    const width = useMediaQuery('(min-width:645px)');
    return (
        <Fragment>
            <section id="markets">
                <br />
                <h2>Markets</h2>
                <FadeInSection>
                    <Grid container >
                        <Grid item xs={12} lg={3}>
                            <Card className={classes.root} >
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        alt="Trailblazers"
                                        height="120"
                                        image={trailblazersLogo}
                                        title="Trailblazers"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            TRAILBLAZERS
          </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            TRAILBLAZERS INSURANCE COMPANY, INC. RRG specializes in Commercial Auto Liability.
          </Typography>
                                    </CardContent>
                                </CardActionArea>

                            </Card>
                        </Grid>
                    </Grid>
                </FadeInSection>
            </section>
        </Fragment>
    );
}

export default Markets;