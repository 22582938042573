import { Grid, makeStyles, useMediaQuery } from "@material-ui/core";
import { Fragment, React, useLayoutEffect, useState } from "react";
import background from "../Img/cover.jpg"
import logo from "../Img/companyLogo.png"



const useStyles = makeStyles((theme) => ({
    bannerStyle: {
        backgroundImage: `url(${background})`,
        backgroundColor: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    }
}));
const NavBar = () => {
    const classes = useStyles();
    const width = useMediaQuery('(min-width:770px)');

    return (
        <Fragment>
            <header id="home" className={classes.bannerStyle}>
                <nav id="nav-wrap">
                    <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
                    <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

                    <ul id="nav" className="nav">
                        <li className="current"><a className="smoothscroll" href="#home">HOME</a></li>
                        <li><a className="smoothscroll" href="#coverages">COVERAGES</a></li>
                        <li><a className="smoothscroll" href="#markets">MARKETS</a></li>
                        <li><a className="smoothscroll" href="#about">ABOUT US</a></li>
                        <li><a className="smoothscroll" href="#getquote">GET A QUOTE</a></li>
                        <li><a className="smoothscroll" href="#becomeproducer">BECOME A PRODUCER</a></li>
                        <li><a className="smoothscroll" href="#contact">CONTACT US</a></li>
                        <li><a className="smoothscroll" href="#resources">RESOURCES</a></li>
                        <li><a className="smoothscroll" href="#claims">CLAIMS</a></li>
                        <li><a className="smoothscroll" href="#careers">CAREERS</a></li>
                        <li><a className="smoothscroll" href="#preferedvendors">VENDORS</a></li>
                    </ul>
                </nav>

                {
                    !width ?
                        <Grid container style={{ float: 'left' }}>
                            <Grid item xs={false} lg={6} md={6}>

                            </Grid>
                            <Grid item xs={12} lg={6} md={6} >
                                <div className="banner-text">
                                    <img src={logo} width="40%"></img>
                                </div>
                            </Grid>
                        </Grid>
                        :

                        <Grid container style={{ float: 'left', marginTop: '70px' }}>
                            <Grid item xs={false} sm={6} lg={6} md={6}>

                            </Grid>
                            <Grid item xs={12} sm={6} lg={6} md={6}>
                                <div className="banner-text">
                                    <img src={logo} width="40%"></img>
                                </div>
                            </Grid>
                        </Grid>
                }


                <p className="scrolldown">
                    <a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
                </p>

            </header>
        </Fragment>
    );
}

export default NavBar;