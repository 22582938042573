import { Button, Card, CardContent, CardMedia, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { Fragment, React, useState } from "react";
import { useQuery, gql, useMutation } from '@apollo/client';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import TransitionsModal from "./Helpers/TransitionModal";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        color: '#838C95',
        padding: 10,
        backgroundColor: 'transparent',
        boxShadow: 'none'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    pos: {
        marginBottom: 12,
        fontSize: 15
    },
    coveragesBackground: {
        // backgroundImage: `url(${background}) !important`,
        // backgroundSize: '50% 100%',
        backgroundColor: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
    },
    coveragesBackgroundMobil: {
        // backgroundImage: `url(${background}) !important`,
        // backgroundSize: '100% 100%',
        backgroundColor: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
});
const BECOME_PRODUCER_FORM = gql`
  mutation addBecomeProducer($input: become_producer_input) {
    addBecomeProducer(input: $input) {
        id,
        corporationType,
        fullName,
        DBA,
        address,
        city,
        zip,
        state,
        phone,
        email,
        taxIdSS,
        licenseNumber,
        licenseState,
        licenseExpDate,
        statesWriteBusinessIn,
        businessVolumeLia,
        businessVolumePD,
        businessVolumeCargo,
        commitmentToUs,
        lossRatio,
        remarks,
        requestNumber
    }
  }
`;

const BecomeProducer = () => {
    //const initials ======================================================================================================
    const classes = useStyles();

    //local constants ======================================================================================================
    const [saveForm] = useMutation(BECOME_PRODUCER_FORM);
    const [inputKey, setInputKey] = useState('asd')
    const [file, setFile] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [bapForm, setBapForm] = useState({
        id: uuidv4(),
        corporationType: "",
        fullName: "",
        DBA: "",
        address: "",
        city: "",
        zip: "",
        state: "",
        phone: "",
        email: "",
        taxIdSS: "",
        licenseNumber: "",
        licenseState: "",
        licenseExpDate: "",
        statesWriteBusinessIn: "",
        businessVolumeLia: "",
        businessVolumePD: "",
        businessVolumeCargo: "",
        commitmentToUs: "",
        lossRatio: "",
        remarks: "",
    })
    //handles (react-dom) ======================================================================================================
    const handleChange = (e, field) => {
        setBapForm({
            ...bapForm,
            [e.target.name]: e.target.value
        })
    }
    const handleCorpTypeChange = (e) => {
        setBapForm({
            ...bapForm,
            [e.target.name]: e.target.value
        })
    }
    const handleReset = () => {
        setInputKey(Math.random().toString(36))
        setBapForm({
            id: uuidv4(),
            corporationType: "",
            fullName: "",
            DBA: "",
            address: "",
            city: "",
            zip: "",
            state: "",
            phone: "",
            email: "",
            taxIdSS: "",
            licenseNumber: "",
            licenseState: "",
            licenseExpDate: "",
            statesWriteBusinessIn: "",
            businessVolumeLia: "",
            businessVolumePD: "",
            businessVolumeCargo: "",
            commitmentToUs: "",
            lossRatio: "",
            remarks: "",
        })

    }

    const handleSave = async e => {
        saveForm({ variables: { input: bapForm } }).then((res) => {
            console.log(res);
            const formData = new FormData();
            Array.from(file).map(fileSend => {
                formData.append('file', fileSend);
            })
            formData.append('formId', res.data.addBecomeProducer.id);
            formData.append('requestNumber', res.data.addBecomeProducer.requestNumber);
            formData.append('requestEmail', res.data.addBecomeProducer.email);
            try {
                const res = axios.post('http://server.thetranspro.com/upload', formData, {
                    //const res = axios.post('http://localhost:8080/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })


            } catch (error) {

            }
            handleReset();
            setModalOpen(true);
        });
    }

    const handleFilesChange = (e) => {
        //let filesList = Array.from(e.target.files).map((file => file))
        setFile(e.target.files);
    }


    return (
        <Fragment>
            <section id="becomeproducer">
                <h2>Become a producer</h2>
                <Grid container spacing={1} style={{ background: '#F9F9F9', color: 'black' }}>
                    <Grid item xs={12} md={2} lg={2}>
                        <FormControl variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}>
                            <InputLabel id="demo-simple-select-outlined-label" >Corporation Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={bapForm.corporationType}
                                onChange={handleCorpTypeChange}
                                label="Corporation Type"
                                name="corporationType"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={"Corporation"}>Corporation</MenuItem>
                                <MenuItem value={"LLC"}>LLC</MenuItem>
                                <MenuItem value={"Individual"}>Individual</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        Full Name
                            </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="fullName"
                            value={bapForm.fullName}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        DBA
                            </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="DBA"
                            value={bapForm.DBA}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        Address
                            </Grid>
                    <Grid item xs={12} md={5} lg={5}>
                        <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="address"
                            value={bapForm.address}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        City
                            </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="city"
                            value={bapForm.city}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        Zip
                            </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="zip"
                            value={bapForm.zip}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        State
                            </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="state"
                            value={bapForm.state}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        Phone
                            </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="phone"
                            value={bapForm.phone}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        Email
                            </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="email"
                            value={bapForm.email}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        Tax ID / SS
                            </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="taxIdSS"
                            value={bapForm.taxIdSS}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        License #
                            </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="licenseNumber"
                            value={bapForm.licenseNumber}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        State
                            </Grid>
                    <Grid item xs={12} md={2} lg={2}>
                        <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="licenseState"
                            value={bapForm.licenseState}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        Exp Date
                            </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="licenseExpDate"
                            value={bapForm.licenseExpDate}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={false} md={1} lg={1}>
                    </Grid>
                    <Grid item xs={12} md={3} lg={3} style={{ fontSize: 14 }}>
                        State(s) you want to write business in?
                            </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="statesWriteBusinessIn"
                            value={bapForm.statesWriteBusinessIn}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        Volume of your business
                            </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        <TextField label="Lia" size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="businessVolumeLia"
                            value={bapForm.businessVolumeLia}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        <TextField label="PD" size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="businessVolumePD"
                            value={bapForm.businessVolumePD}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        <TextField label="Cargo" size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="businessVolumeCargo"
                            value={bapForm.businessVolumeCargo}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={2} lg={2}>
                        Commitment to us
                            </Grid>
                    <Grid item xs={12} md={2} lg={2}>
                        <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="commitmentToUs"
                            value={bapForm.commitmentToUs}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={2} lg={2}>
                        Loss ratio
                            </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="lossRatio"
                            value={bapForm.lossRatio}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={false} md={3} lg={3}>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        Please upload all your licenses and E&O
                            </Grid>
                    <Grid item xs={12} md={5} lg={5}>
                        <input onChange={handleFilesChange} key={inputKey} type="file" multiple fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}></input>
                    </Grid>
                    <Grid item xs={false} md={3} lg={3}>
                    </Grid>
                    <Grid item xs={12} md={2} lg={2}>
                        Remarks
                            </Grid>
                    <Grid item xs={12} md={5} lg={5}>
                        <TextField multiline rows={4} size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="remarks"
                            value={bapForm.remarks}
                            onChange={handleChange} />
                    </Grid>


                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" onClick={handleReset}>
                            Reset
                                </Button>
                        <Button variant="contained" onClick={handleSave} style={{ marginLeft: '15px', backgroundColor: '#7af043' }}>
                            Submit
                                </Button>
                    </Grid>
                </Grid>
                <TransitionsModal isOpen={modalOpen} setModalOpen={setModalOpen}></TransitionsModal>
            </section>
        </Fragment>
    );
}

export default BecomeProducer;